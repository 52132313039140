.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  padding: 5vw;
  background-color: #eee;
}

.search-panel-result-voc-card {
  margin: 10px;
  min-width: 200px;
}

.shapes-card {
  /* margin: 10px; */
  min-width: 20vw;
}

.search-panel-chart {
  height: 100%;
  /* margin: 5px; */
  /* padding: 2px; */
  background-color: white;
  /* border-radius: 5px; */
}

.app-canvas-section {
  padding: 5vw;
  background-color: white;
  /* margin-left: 60px; */
  /* margin-right: 60px; */
}

/* .ant-layout-content::before {
  display: block; 
  content: " "; 
  margin-top: -285px; 
  height: 285px; 
  visibility: hidden; 
  pointer-events: none;
} */

.ant-layout-content {
  margin-bottom: 20px;
}

header {
    height: auto;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

body {
  font-family: "Montserrat",sans-serif;
}